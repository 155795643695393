import { Observable } from 'rxjs';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  constructor(
    private apiService: ApiService
  ) { }

  setGetParams(data: any) {
    let parameters = '?';
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      if (i > 0) {
        parameters += '&';
      }
      parameters += `${entries[i][0]}=${entries[i][1]}`;
    }
    return parameters;
  }

  emailInvite (email: string): Observable<any> {
    return this.apiService.call<any>('emailInvite', {email: email});
  }

  getReferralSummary(data: any): Observable<any> {
    return this.apiService.call<any>(`getMyReferralSummary${this.setGetParams(data)}`);
  }

  getUserFee() {
    return this.apiService.call<any>('getUserFee');
  }

  getReferredUsersInfo() {
    return this.apiService.call<any>('getReferredUsersInfo');
  }
}
