import { Injectable } from '@angular/core';

import { ApiService } from '../core/api/api.service';
import { SessionStorageService, SubscriptionLike } from 'src/app/core/services/session-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  private subs: SubscriptionLike[] = [];

  environment: any = environment.config;
  loggedIn: boolean = false;
  darkTheme: boolean = false;

  constructor(
    private apiService: ApiService,
    public sessionStorage: SessionStorageService) {

    this.subs.push(this.sessionStorage.observe('THEME').subscribe(
      (data) => {
        this.darkTheme = data === 'dark-theme';
      }
    ));

    this.subs.push(this.sessionStorage.observe('LOGGED_IN').subscribe(
      () => {
        if (this.sessionStorage.get('LOGGED_IN') !== '' &&
          this.sessionStorage.get('TOKEN') !== '') {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      }
    ));

  }

  changeTheme() {
    this.sessionStorage.store('THEME', this.darkTheme ? 'light-theme' : 'dark-theme');
  }

  getMarketData() {
    return this.apiService.call('getMarkets');
  }

  getWithdrawFees() {
    return this.apiService.call('getWithdrawFees');
  }

  getScalingFees() {
    return this.apiService.call<any>('getScalingFees');
  }

  getUserFee() {
    return this.apiService.call<any>('getUserFee');
  }
}
