import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private apiService: ApiService) { }

  setGetParams(data: any) {
    let prams = '?';
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      if ( prams !== '?' && entries[i][1] !== '') {
        prams += '&';
      }
      if (entries[i][1] !== '') {
        prams += `${entries[i][0]}=${entries[i][1]}`;
      }
    }
    return prams;
  }

  getChartData(data: any) {
    return this.apiService.call<any>(`chart/history${this.setGetParams(data)}`, null, true);
  }
}
